import React from "react"
import Player from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlay,
  faPause,
  faBackward,
  faForward,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons"

export default class AudioPlayer extends React.Component {
  //i.e., this.props.mediaUrl is the one encountering such issue
  constructor(props) {
    super(props)
    this.player = React.createRef()
    this.state = {
      audioBlobURL: "",
      isAudioBlobCreated: false,
    }
  }

  async componentDidMount() {
    let blob = await fetch(this.props.src).then(r => r.blob())
    this.setState({ audioBlobURL: URL.createObjectURL(blob) }, () =>
      this.setState({ isAudioBlobCreated: true }),
    )
  }

  render() {
    return !!this.props.src && this.state.isAudioBlobCreated ? (
      <div className="mb-sm">
        <Player
          showDownloadProgress={false}
          src={this.state.audioBlobURL}
          ref={this.player}
          showFilledVolume={true}
          customIcons={{
            play: <FontAwesomeIcon icon={faPlay} />,
            pause: <FontAwesomeIcon icon={faPause} />,
            rewind: <FontAwesomeIcon icon={faBackward} />,
            forward: <FontAwesomeIcon icon={faForward} />,
            volume: <FontAwesomeIcon icon={faVolumeHigh} />,
            volumeMute: <FontAwesomeIcon icon={faVolumeXmark} />,
          }}
        />
      </div>
    ) : (
      <div className="mb-sm rhap_loading flex flex--align-center flex--justify-center">
        <p className="h4 text--small">Loading...</p>
      </div>
    )
  }
}
