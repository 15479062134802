import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Copy from "../content/copy"
import Link from "../content/link"
import ConditionalWrapper from "../../utils/ConditionalWrapper"

function AuthorBox({ author }) {
  const authorUrl = `/blog/author/${author.firstName.toLowerCase()}-${author.lastName.toLowerCase()}`
  const authorImage = getImage(
    author.authorDetails.coreDetails.profileImage.localFile,
  )

  return (
    <aside className="blog-post__author mb-md tab-mb-lg tab-ml-xs">
      <div className="blog-post__author__tag">
        <p className="text--strong text--small">Author</p>
      </div>
      <div className="blog-post__author__profile">
        <div className="blog-post__author__image relative">
          <GatsbyImage image={authorImage} alt="author image" />
        </div>
        <div className="tab-ml-xs">
          <p className="text--strong mb-xxs">
            <ConditionalWrapper
              condition={author.authorDetails.hasAuthorPage}
              wrapper={children => <Link to={authorUrl}>{children}</Link>}
            >
              {author.firstName} {author.lastName},{" "}
              {author.authorDetails.coreDetails.content.jobTitle}
            </ConditionalWrapper>
          </p>
          <Copy
            className="blog-post__author__copy"
            contentToParse={author.authorDetails.coreDetails.content.biography}
          />
        </div>
      </div>
    </aside>
  )
}

export default AuthorBox
