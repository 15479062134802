import React, { useState, useEffect, useRef } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Rows } from "../../components/grid"
import Heading from "../../components/content/heading"
import Copy from "../../components/content/copy"
import SubSection from "../../components/containers/section-sub"
import BlogCategory from "../../components/accents/BlogCategory"
import parseBlogContent from "../../utils/parse-blog-content"
import TryUsBar from "../../components/sections/try-us-bar"
import SectionBackground from "../../components/containers/section-background"
import HeadingAccent from "../../components/accents/heading-accent"
import PostsCarousel from "../../components-smart/carousel/posts-carousel"
import Link from "../../components/content/link"
import AuthorBox from "../../components/blog/author-box"
import parseBlogSidebar from "@src/utils/parse-blog-sidebar"
import CtaGroup from "@src/components/containers/cta-group"
import ReactPlayer from "react-player/lazy"
import AudioPlayer from "@src/components/interactive/audio-player"
import RelatedPost from "@src/components/blog/related-post"

function Article({ data }) {
  const {
    title,
    date,
    ogDate,
    modified,
    featuredImage,
    seo,
    realSlug,
    author,
    categories,
    content,
    blogSchema,
  } = data.wpPost

  const seoData = {
    title: seo.title,
    description: seo.metaDesc,
    image: featuredImage.node.localFile.publicURL,
    postImageAlt: featuredImage.node.altText,
    datePublished: ogDate,
    postCategory: categories.nodes[0].name,
    dateModified: modified,
    author: `${author.node.firstName} ${author.node.lastName}`,
    additionalSchema: blogSchema.additionalschema
      ? JSON.parse(blogSchema.additionalschema)
      : false,
    url: realSlug,
  }

  const coverImage = getImage(featuredImage.node.localFile)

  const [percentScrollY, setPercentScrollY] = useState(0)
  const blogContent = useRef(null)

  function logit() {
    let percentScroll = (
      window.pageYOffset / blogContent.current.offsetHeight
    ).toFixed(3)
    if (percentScroll < 0) percentScroll = 0
    if (percentScroll > 1) percentScroll = 1
    setPercentScrollY(percentScroll)
  }

  useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", logit)
    }
    watchScroll()
    return () => {
      window.removeEventListener("scroll", logit)
    }
  })

  return (
    <Main>
      <Seo customData={seoData} isBlogPost />
      <header>
        <Section theme="dark" wave="blog">
          <SectionBackground>
            <div className="blog-post__cover">
              <GatsbyImage
                className="width--full height--full blog-post__cover__image"
                image={coverImage}
                alt={featuredImage.node.altText}
              />
            </div>
            <div className="blog-post__cover blog-post__cover__overlay" />
          </SectionBackground>
          <SectionContent hero className="tab-mb-lg" paddingTop="lg">
            <Rows className="tab-mb-lg">
              <Columns count="1" className="mob-mb-lg tab-mb-xl tab-mt-lg">
                <Content paddingLeft="none" paddingRight="none">
                  <CtaGroup alignment="center">
                    <p>
                      <Link className="link--light" to="/blog/">
                        Blog
                      </Link>
                    </p>{" "}
                    <p>/</p>{" "}
                    <p>
                      <Link
                        className="link--light"
                        to={`/blog/${categories.nodes[0].slug}/`}
                      >
                        {categories.nodes[0].name}
                      </Link>
                    </p>
                  </CtaGroup>
                  <Heading alignment="center" level={1}>
                    {title}
                  </Heading>
                  <Copy>
                    <p className="h4 text--center">{date}</p>
                  </Copy>
                </Content>
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section className="section--blog">
        <SectionContent paddingTop="xxs">
          <Rows>
            <Columns count="1,3">
              <Content
                paddingLeft="none"
                className="blog-post__aside hidden--lap-down"
              >
                <div className="blog-post__sidebar">
                  {blogSchema && blogSchema.audioTranscript && (
                    <AudioPlayer src={blogSchema.audioTranscript.publicUrl} />
                  )}
                  <Rows gap="sm">
                    <h4>Contents</h4>
                    <Rows gap="xs">{parseBlogSidebar(content)}</Rows>
                  </Rows>
                </div>
              </Content>
              <article ref={blogContent}>
                <AuthorBox author={author.node} />
                <Content paddingRight="none">
                  <Copy className="blog-post__content">
                    {parseBlogContent(content)}
                  </Copy>
                </Content>
              </article>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      {blogSchema &&
        blogSchema.relatedPosts &&
        !!blogSchema.relatedPosts.length && (
          <Section>
            <SectionContent paddingTop="none" paddingBot="sm">
              <Rows>
                <Columns count="1">
                  <Content paddingLeft="sm">
                    <Heading preheading="You may also like...">
                      Related Posts
                    </Heading>
                  </Content>
                </Columns>
                <Columns count="3">
                  {blogSchema.relatedPosts.map(
                    (
                      {
                        title,
                        date,
                        author,
                        categories,
                        realSlug,
                        featuredImage,
                      },
                      index,
                    ) => (
                      <RelatedPost
                        key={index}
                        title={title}
                        date={date}
                        author={author}
                        category={categories.nodes[0]}
                        realSlug={realSlug}
                        featuredImage={featuredImage}
                      />
                    ),
                  )}
                </Columns>
              </Rows>
            </SectionContent>
          </Section>
        )}

      <TryUsBar />
      <div className="blog__scroll-percent">
        <div
          className="blog__scroll-percent__inner"
          style={{
            width: `${100 * percentScrollY}%`,
          }}
        />
      </div>
    </Main>
  )
}

export default Article

export const postQuery = graphql`
  query ($id: String!) {
    wpPage(slug: { eq: "blog" }) {
      blogPage {
        blogpostSidebar {
          link
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM Do, YYYY")
      ogDate: date
      modified
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          altText
        }
      }
      realSlug
      seo {
        title
        metaDesc
      }
      categories {
        nodes {
          data {
            color
          }
          slug
          name
        }
      }
      author {
        node {
          firstName
          lastName
          authorDetails {
            hasAuthorPage
            coreDetails {
              content {
                jobTitle
                biography
              }
              profileImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      content
      blogSchema {
        audioTranscript {
          publicUrl
        }
        additionalschema
        relatedPosts {
          ... on WpPost {
            date(formatString: "MMMM Do, YYYY")
            author {
              node {
                firstName
                lastName
              }
            }
            title
            categories {
              nodes {
                name
                slug
                data {
                  color
                }
              }
            }
            realSlug
            featuredImage {
              node {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                altText
              }
            }
          }
        }
      }
    }
  }
`
