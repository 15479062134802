import React from "react"
import Content from "../grid/content"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../content/link"
import Rows from "../grid/rows"

const RelatedPost = ({
  title,
  date,
  author,
  category,
  realSlug,
  featuredImage,
}) => {
  const img = getImage(featuredImage.node.localFile)

  return (
    <Content gap="md" paddingLeft="sm">
      <Link to={realSlug} className="blog__related border--rounded">
        <GatsbyImage
          className="blog__related__image"
          image={img}
          alt={featuredImage.node.altText}
        />
      </Link>
      <Rows gap="xxs">
        <div>
          <Link
            to={`/blog/${category.slug}/`}
            className={`h4 text--small text-gradient text-gradient__${category.data.color}`}
          >
            {category.name}
          </Link>
        </div>
        <Link className="h4" to={realSlug}>
          {title}
        </Link>
        <p className="h4 text--small">
          {author.node.firstName} {author.node.lastName}
          <span className="mr-xxs ml-xxs">|</span>
          {"  "}
          {date}
        </p>
      </Rows>
    </Content>
  )
}

export default RelatedPost
